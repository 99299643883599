.estimate-section-item {
  .top-section-boq {
    .chart-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 10px;

      .chart-wrapper {
        width: 50%;
        margin: 5px;
        padding: 10px;
      }
    }

    .summary-container {
      margin: 20px;
      padding: 0 4px;

      .summary-chip-parent {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .summary-chip {
          display: flex;
          flex-direction: row;
          margin: 10px auto;
          width: 100%;
          justify-content: space-around;

          .summary-chip-label {
            margin: 0 10px 0 16px;
            line-height: 32px;
            font-weight: 900;
            font-size: 1rem;
          }

          .summary-chip-value {
            font-size: larger;
            font-weight: 900;
            width: 16rem;
            height: 36px;
            padding: 6px 6px 6px 10px;
            color: #fff;
            background-color: #b2dcc6;
            border-radius: 6px;
            border-color: #ccc;
            vertical-align: middle;

            @media (max-width: 990px) {
              width: 14rem;
            }

            @media (min-width: 564px) and (max-width: 864px) {
              width: 21rem;
            }
          }

          @media (max-width: 864px) {
            display: flex;
            justify-content: space-between;
          }
        }

        @media (max-width: 864px) {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .top-heading {
    padding: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    line-height: 20px;
    font-size: 1.2rem;

    label {
      display: flex;
      flex: 1;
      align-items: left;
    }
  }

  .estimate-section-item-grid {
    .bg-green {
      background-color: #b2dcc6;
    }

    .bg-added {
      background-color: #ffffc5;
    }

    .compact-grid-tree {
      .dx-data-row {
        td {
          padding: 12px 10px !important;
        }
      }

      .tree-grid-column-bold {
        font-weight: bold;
      }

      .composite-item {
        td {
          background-color: #cee9db;
        }
      }

      .composite-resource {
        td {
          background-color: #d5e4cc;
        }
      }

      .normal-item {
        td {
          background-color: #e2f2e9;
        }
      }

      .composite-total {
        td {
          background-color: #b2dcc6;
        }
      }

      .sub-item {
        td {
          background-color: rgb(232, 248, 244);
        }
      }

      .cell-description {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
          margin-left: 5px;
        }
      }
    }
  }
}

.boq-update-resource-popup-wrapper {
  .dx-accordion {
    .dx-accordion-wrapper {
      .dx-accordion-item-opened .dx-accordion-item-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
      }
    }
  }
}
