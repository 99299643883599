.estimate-lib-setup-main {
  .sub-header {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;

    .file-control-btns {
      display: flex;
      justify-content: flex-end;

      a {
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        i {
          width: 18px;
          height: 18px;
          font-size: 18px;
          color: #1f1f1f;
        }
      }
    }
  }
}

.contractorLibrarySetupMain {
  .libraryManagementHeader {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
  }

  padding: 0px;

  .dx-datagrid {
    max-height: calc(100vh - 7.75rem);
  }

  .roundBorderGreenBtn {
    background-color: #54bc0e;
    color: #fff;
    .dx-button-text {
      text-transform: capitalize;
      font-weight: bold;
    }
  }
}

.supplierLibraryPopup {
  .supplierRateTable {
    display: flex;
    margin-top: 2rem;
  }
}
.addLibraryPopup {
  .popUpSubHeading {
    display: flex;
    gap: 0.625rem;
    align-items: center;

    .role {
      font-weight: bolder;
      margin: 0;
    }

    .contractors {
      border: 1.5px solid black;
      font-size: 16px;
      padding: 5px;
      width: 360px;
      font-weight: "bold";
      border-radius: 8px;
    }
  }
}
