@import "../../themes/generated/variables.base.scss";

.estimateRevisionMgmntMain {
  padding: 0px;

  .dx-datagrid {
    max-height: calc(100vh - 7.75rem);
  }

  .dx-row.dx-data-row.dx-column-lines td {
    transition: background-color 0.2s ease-in, color 0.3s ease-out;
  }

  .dx-row.dx-data-row.dx-column-lines.dx-state-hover td {
    cursor: pointer;
    background-color: rgba($base-accent, 0.15) !important;
  }

  .addRevisionBtn {
    background-color: #54bc0e;
    color: #fff;
    padding: 12px;
    margin-left: 1rem;
    border-radius: 12px;
    transition: background-color 0.2s ease-in, color 0.3s ease-out;

    .dx-button-text {
      text-transform: capitalize;
      font-weight: bold;
    }

    &:hover {
      background-color: #3c850a !important;
    }
  }

  .erm-context-container {
    .erm-context-btn {
      border-radius: 50%;
      .dx-icon {
        color: white !important;
        font-size: 1.75rem;
        margin: 0.063rem 0 0 -0.031rem;
      }
    }
  }
}

.manageContractorPopUp {
  padding: 1rem;

  .popUpSubHeading {
    display: flex;
    gap: 0.625rem;
    align-items: baseline;

    .role {
      font-weight: bolder;
      margin: 0;
    }

    .contractors {
      border: 1.5px solid black;
      font-size: 16px;
      padding: 5px;
      width: 360px;
      font-weight: "bold";
      border-radius: 8px;
    }
  }

  .contractorsTable {
    display: flex;
    margin-top: 2rem;

    .arrowIcons {
      display: flex;
      flex-direction: column;
      justify-content: center;

      svg {
        margin: 10px;
      }
    }
  }
}

.editRevisionPopUp {
  .dx-editor-filled.dx-texteditor-with-floating-label {
    margin: 0.5rem;
  }

  .save-btn {
    padding: 1rem 8rem !important;
    .dx-button:hover,
    :active,
    ::after,
    ::before,
    :focus {
      background-color: #3c850a !important;
    }
  }
}
