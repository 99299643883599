.subitem-modal-pop-up {
  .subitem-group-top-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .subitem-group-bottom-row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
}
