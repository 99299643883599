@import "../../themes/generated/variables.base.scss";

.custom-breadcrum::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  transition: background-color 0.2s ease-in, color 0.3s ease-out;
}
.custom-breadcrum::-webkit-scrollbar-thumb {
  color: transparent;
  border-radius: 12px;
  transition: background-color 0.2s ease-in, color 0.3s ease-out;
}
.custom-breadcrum::-webkit-scrollbar-thumb:hover {
  background: #c5c5c5;
}
.custom-breadcrum::-webkit-scrollbar-thumb:window-inactive {
  background: #f1f1f1;
}

.custom-breadcrum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  transition: background-color 0.2s ease-in, color 0.3s ease-out;

  .button {
    margin-right: 15px;
  }

  .content-block {
    display: flex;
    margin: 0 !important;
    padding: 10px 0px 10px 15px;

    a {
      cursor: pointer;
      text-decoration: none;
      color: $base-accent;
    }

    span {
      display: flex;
      align-items: center;
    }

    label {
      cursor: pointer;
      max-width: 292px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    i {
      font-size: 18px;
      font-style: normal;
      // text-decoration: underline;

      &.arrow {
        color: $base-text-color;
        margin-left: 10px;
        margin-right: 10px;
        text-decoration: none;
        font-weight: 900;
      }
    }

    h6 {
      margin: 0px;
      display: flex;
      align-items: center;
      font-size: 18px;
    }
  }
}
