.ift-spend-grid-wrapper {
  .ift-spend-grid {
    .heading-row {
      td {
        background-color: rgb(211, 211, 211);
      }
    }
    .invalid-data-row {
      td {
        border: 1px solid rgb(255, 123, 123) !important;
      }
    }
    .duplicate-data-row {
      td {
        background-color: aqua;
      }
    }
    .dx-link {
      color: #363640;
    }
    .time-division-edit-cell {
      border: transparent;
      outline: none;
      padding: 14px 16px;
    }
    .time-division-cell {
      display: flex;
      width: 100%;
      justify-content: space-around;
      .value {
        flex: 1;
        display: flex;
        justify-content: center;
      }
      .separator {
        border-left: 0.15rem solid #c3c3c3;
      }
    }
  }
}
