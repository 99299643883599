.changePwdMain {
  display: flex;

  form {
    flex: 8;

    .pwdMatch {
      display: flex;
      justify-content: center;
      gap: 4px;
      font-weight: 500;

      i {
        color: #54bc0e;
        font-weight: bolder;
      }
    }
  }

  .pwdReq {
    flex: 7;

    .pwdReqTitle {
      padding: 0 12px;
    }

    ul {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
