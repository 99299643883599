.risk-register-container {
  .parent-wrapper {
    background-color: #363640;
    color: white;
  }

  .col-sub-header-title {
    white-space: normal;
  }

  .toolbar-wrapper {
    background-color: #ddd;
  }

  .heading-text {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: inherit;
  }
}
