.ExpenditureProfileReport::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  transition: background-color 0.2s ease-in, color 0.3s ease-out;
}
.ExpenditureProfileReport::-webkit-scrollbar-thumb {
  color: transparent;
  border-radius: 12px;
  transition: background-color 0.2s ease-in, color 0.3s ease-out;
}
.ExpenditureProfileReport::-webkit-scrollbar-thumb:hover {
  background: #c5c5c5;
}
.ExpenditureProfileReport::-webkit-scrollbar-thumb:window-inactive {
  background: #f1f1f1;
}

.ExpenditureProfileReport {
  height: 92vh;
  overflow-y: scroll;
  scroll-behavior: smooth;

  .expenditure-profile-report-head {
    margin: 0px 20px 10px 20px;

    .detail-tiles-row::-webkit-scrollbar {
      width: 5px;
      height: 6px;
      transition: background-color 0.2s ease-in, color 0.3s ease-out;
    }
    .detail-tiles-row::-webkit-scrollbar-thumb {
      color: transparent;
      border-radius: 12px;
      transition: background-color 0.2s ease-in, color 0.3s ease-out;
    }
    .detail-tiles-row::-webkit-scrollbar-thumb:hover {
      background: #c5c5c5;
    }
    .detail-tiles-row::-webkit-scrollbar-thumb:window-inactive {
      background: #f1f1f1;
    }

    .detail-tiles-row {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      scroll-behavior: smooth;
      transition: background-color 0.2s ease-in, color 0.3s ease-out;
      margin-bottom: 0.5rem;

      .detail-box,
      .detail-box2 {
        padding: 0px 0.5rem;
        display: inline-flex;
        border: 2px solid #c5c5c5;
        border-radius: 6px;
        margin: 0rem 0.5rem;
        background-color: #e5e5e5;
        transition: background-color 0.2s ease-in, color 0.3s ease-out;

        &:hover {
          cursor: default;
          background-color: #ededed;
        }
      }

      .detail-box {
        min-width: 12.25rem;
        &:empty {
          background-color: #e5e5e5;
          min-height: 1.5rem;
          vertical-align: bottom;
        }
      }

      .detail-box2 {
        margin: 0rem;
      }
    }

    .expenditure-profile-header-block {
      padding: 0.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .reportHeading {
        display: flex;
        width: fit-content;
      }

      .expenditure-profile-header-block-2child {
        .dropdown {
          padding: 0.25rem;
          display: flex;
          align-items: center;

          @media (max-width: 864px) {
            margin-top: 0.5rem;
          }
        }
      }

      @media (max-width: 864px) {
        grid-template-columns: 1fr;
      }
    }

    .details-table {
      width: 100%;

      td {
        border: 1px solid black;
        width: 50%;
        padding: 0.5rem;
        background-color: #fff;

        @media (max-width: 864px) {
          width: 10%;
        }
      }
    }
  }

  .expenditure-report-grid {
    max-height: calc(100vh - 280px);

    .bg-org {
      background-color: #b2dcc6;
    }
    .bg-section {
      background-color: #e2f2e9;
    }
    .bg-composite-total {
      background-color: #e8f8f4;
    }

    @media (max-width: 1095px) {
      max-height: calc(100vh - 520px);
    }
    @media (max-width: 973px) {
      max-height: calc(100vh - 240px);
    }
  }
}

.exp-report-header-cell {
  display: flex;
  align-items: flex-end;

  .exp-report-carbon-toggle {
    margin-right: 0.625rem;
    border: 0.111rem solid #e5e5e5;
    padding: 0.1rem 0.156rem;
    border-radius: 50%;
    font-size: 14px;
    transition: background-color 0.2s ease-in, color 0.3s ease-out;

    &:hover {
      background-color: #626273;
      color: #fff;
    }
  }

  .exp-report-header-value {
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
