.iftLevelMain {
  .estimateLevelHeader {
    margin: 16px;
    padding: 16px;
    display: flex;
    gap: 24px;
    align-items: center;

    .dropdown {
      display: flex;
      gap: 12px;
      align-items: center;

      label {
        font-weight: bold;
      }
    }
  }

  .compact-grid-tree {
    label {
      // font-weight: bold;
      margin-right: 0.25rem;
    }

    .dx-data-row {
      td {
        padding: 12px 10px !important;
        height: 48px;
      }
    }

    .bg-green {
      td {
        background-color: #b2dcc6;
      }
    }

    .tree-grid-column-bold {
      font-weight: bold;
    }

    .cell-description {
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        margin-left: 0.25rem;
      }
    }
  }
}
