.keyDataMain::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  transition: background-color 0.2s ease-in, color 0.3s ease-out;
}
.keyDataMain::-webkit-scrollbar-thumb {
  color: transparent;
  border-radius: 12px;
  transition: background-color 0.2s ease-in, color 0.3s ease-out;
}
.keyDataMain::-webkit-scrollbar-thumb:hover {
  background: #c5c5c5;
}
.keyDataMain::-webkit-scrollbar-thumb:window-inactive {
  background: #f1f1f1;
}

.keyDataMain {
  padding: 0px 20px;

  .headTitleRow {
    display: flex;
    justify-content: space-between;

    h6 {
      font-weight: bold;
      font-size: 22px;
    }
  }

  .headFields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    .fields {
      display: flex;
      justify-content: space-between;

      label {
        font-weight: bold;
        flex: 1;
      }

      input {
        flex: 2;
      }
    }

    @media (max-width: 1240px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 680px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .keyDataBody::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    transition: background-color 0.2s ease-in, color 0.3s ease-out;
  }
  .keyDataBody::-webkit-scrollbar-thumb {
    color: transparent;
    border-radius: 12px;
    transition: background-color 0.2s ease-in, color 0.3s ease-out;
  }
  .keyDataBody::-webkit-scrollbar-thumb:hover {
    background: #c5c5c5;
  }
  .keyDataBody::-webkit-scrollbar-thumb:window-inactive {
    background: #f1f1f1;
  }

  .keyDataBody {
    margin: 1.5rem 0rem;

    h6 {
      font-weight: bold;
      font-size: 22px;
    }
  }

  @media (max-height: 811px) {
    height: 80vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
}
