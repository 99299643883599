.epd-popup-wrapper {
  .strip {
    display: flex;
    justify-content: center;
    padding: 5px;
    background-color: #6c6c6c;
    margin-top: 10px;
    margin-bottom: 10px;
    label {
      font-size: 1rem;
      font-weight: 900;
      color: #fff;
      align-self: center;
    }
  }

  .label-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .label {
    margin-top: 15px;
    margin-right: 10px;
    font-size: 0.8rem;
  }

  .margin-10 {
    margin: 6px;
  }
  input[type="text"] {
    font-size: 0.8rem;
  }
  .dx-label {
    font-size: 0.6rem;
  }
  .button-success {
    background-color: #54bc0e;
    color: #fff;
  }
  .link {
    cursor: pointer;
  }
}
