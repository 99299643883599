@import "../../themes/generated/variables.base.scss";

.estimate-section-wrapper {
  .project-top-section {
    .chart-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 10px;

      .chart-wrapper {
        width: 50%;
        margin: 10px;
        padding: 10px;
      }
    }

    .summary-container {
      display: flex;
      flex-direction: column;
      margin: 20px;
      padding: 0 4px;

      .summary-title {
        align-self: center;
        margin: 10px;
        font-size: 18px;
        font-weight: 900;
      }

      .summary-chip-parent {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .summary-chip {
          display: flex;
          flex-direction: row;
          margin: 10px auto;
          justify-content: space-evenly;

          .summary-chip-label {
            margin: 0 10px 0 16px;
            line-height: 32px;
            width: 9.25rem;
          }

          .summary-chip-value {
            font-size: larger;
            font-weight: 900;
            width: 16rem;
            height: 36px;
            padding: 6px 6px 6px 10px;
            color: #fff;
            background-color: #b2dcc6;
            border-radius: 6px;
            border-color: #ccc;
            vertical-align: middle;

            @media (max-width: 990px) {
              width: 14rem;
            }
            @media (min-width: 564px) and (max-width: 864px) {
              width: 21rem;
            }
          }
        }

        @media (max-width: 864px) {
          grid-template-columns: 1fr;
        }
      }

      .chart-wrapper {
        width: 50%;
        margin: 10px;
        padding: 10px;
      }
    }
  }

  .estimate-section-grid {
    .dx-row.dx-data-row.dx-column-lines td {
      transition: background-color 0.2s ease-in, color 0.3s ease-out;
    }

    .dx-row.dx-data-row.dx-column-lines.dx-state-hover td {
      cursor: pointer;
      background-color: rgba($base-accent, 0.15) !important;
    }

    .grid-title {
      display: flex;
      flex: 1;
      flex-flow: row nowrap;
      background-color: #fff;
      padding: 10px;
      border-bottom: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      line-height: 20px;
      font-weight: 700;
      font-size: 1rem;

      label {
        display: flex;
        flex: 1;
        flex-flow: column nowrap;
        align-items: left;
      }
    }
  }
}

.dx-sort {
  .dx-sort-index-icon {
    visibility: hidden !important;
  }
}
