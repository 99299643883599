.widget-container {
  display: flex;
}

.widget-container > * {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.list-container {
  border: 2px solid silver;
  margin-left: 50;
}

#icon-back,
#icon-disabled-back {
  margin-left: 4px;
}

.fa-disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.fa {
  cursor: pointer;
}
