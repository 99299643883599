.previous-estimate-container {
  display: flex;
  flex-direction: column;
  .parent-wrapper {
    background-color: #363640;
    color: white;
  }

  .col-sub-header-title {
    white-space: normal;
  }

  .toolbar-wrapper {
    background-color: #ddd;
  }

  .heading-text {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: inherit;
  }
}

.previous-estimate-top {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.headerCellBg {
  background-color: #d3d3d3;
  font-weight: bold;
}

.orgTotalBg {
  background-color: #a9a9a9;
  font-weight: bold;
}

.baseCostEstimageBg {
  background-color: #466d1d;
  font-weight: bold;
}

.thirdPartyContributionBg {
  background-color: #f8de7e;
}

.nonRecoverableVatBg {
  background-color: #fffdd0;
}
.vatBg {
  background-color: #fda50f;
}

.textCellBold {
  font-weight: bold;
}
