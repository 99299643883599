.powerBIMain {
  .bi-embedded::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    transition: background-color 0.2s ease-in, color 0.3s ease-out;
  }
  .bi-embedded::-webkit-scrollbar-thumb {
    color: transparent;
    border-radius: 12px;
    transition: background-color 0.2s ease-in, color 0.3s ease-out;
  }
  .bi-embedded::-webkit-scrollbar-thumb:hover {
    background: #c5c5c5;
  }
  .bi-embedded::-webkit-scrollbar-thumb:window-inactive {
    background: #f1f1f1;
  }

  .bi-embedded {
    height: 86vh;
    overflow-y: scroll;
    scroll-behavior: smooth;

    .explorationContainer .exploreCanvas .canvasFlexBox .displayAreaContainer .displayArea {
      left: auto !important;
    }
  }
}
