.dx-treelist-headers .dx-treelist-table {
  td.has-data-picker {
    .dx-editor-container {
      overflow: visible;
    }

    .dx-invalid {
      .dx-dropdowneditor-input-wrapper {
        overflow: visible;

        .dx-texteditor-container {
          overflow: visible;
        }
      }

      .dx-invalid-message {
        visibility: visible; // to display the error box always not only on hover

        .dx-invalid-message-content {
          max-width: 300px !important;
          transform: translate(126px, -30px) !important;
          color: #fff;

          // customize the box
          background-color: transparent !important;

          &:before {
            content: "";
            width: 100%;
            height: 56%;
            border-radius: 2px;
            position: absolute;
            top: 24%;
            left: 0;
            background-color: #e96060;
            z-index: -1;
          }
        }
      }
    }
  }
}
