@import "../../../themes/generated/variables.base.scss";

.expenditureProfileMain {
  padding: 0px;

  .dx-datagrid {
    max-height: calc(100vh - 7.75rem);
    max-width: max-content;

    .bg-grey {
      background-color: #e9e9e9;
    }
  }

  .dx-row.dx-data-row.dx-column-lines td {
    transition: background-color 0.2s ease-in, color 0.3s ease-out;
  }

  .dx-row.dx-data-row.dx-column-lines.dx-state-hover td {
    cursor: pointer;
    background-color: rgba($base-accent, 0.15) !important;
  }
}
