@import "../../themes/generated/variables.base.scss";
//@import "../../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }

  .header-toolbar {
    .dx-button {
      transition: background-color 0.2s ease-in, color 0.3s ease-out !important;
    }
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;

  .dx-button-mode-text.dx-state-focused,
  .dx-button-mode-text.dx-state-hover {
    background-color: transparent;
  }

  .dx-button-content {
    font-size: 20px;
    font-weight: 500;

    .dx-button-text {
      text-transform: none;
      letter-spacing: normal;
    }

    img {
      width: 30px;
      margin-right: 8px;
    }
  }
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
