@import "../../themes/generated/variables.base.scss";

.estimate-browser-wrapper {
  .dx-row.dx-data-row.dx-column-lines td {
    transition: background-color 0.2s ease-in, color 0.3s ease-out;
  }

  .dx-row.dx-data-row.dx-column-lines.dx-state-hover td {
    cursor: pointer;
    background-color: rgba($base-accent, 0.15) !important;
  }

  .project-grid-container {
    max-width: fit-content !important;
  }

  .eb-header-cell {
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .eb-context-container {
    .eb-context-btn {
      border-radius: 50%;
      .dx-icon {
        color: white !important;
        font-size: 1.75rem;
        margin: 0.063rem 0 0 -0.031rem;
      }
    }
  }
}
