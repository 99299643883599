.contractor-specific-table-wrapper {
  .dx-datagrid .dx-data-row > td {
    line-height: 3.125rem !important;
  }

  .align-item-center {
    display: flex;
    justify-content: center;
    .dx-button {
      min-width: 6.25rem;
    }
  }

  .supplier-cell {
    .dx-button {
      min-width: 8.75rem;
    }
  }

  .i-btn {
    .dx-icon {
      color: #54bc0e;
      font-size: 24px;
    }
  }

  .transport-mode-select-box {
    .dx-texteditor-label {
      font-size: 0.625rem !important;
    }
  }

  .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    font-weight: bolder;
    font-size: large !important;
  }

  .bg-grey {
    background-color: #f5f5f5;
    font-weight: bolder;
    font-size: large !important;
  }
}

.supplier-apply-btn {
  background-color: #54bc0e !important;
  color: #fff !important;
}
