.resource-modal-pop-up {
  .resource-group-top-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .resource-group-bottom-row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
}
