.fuel-type-container {
  .border-class {
    width: calc(100% / 2);
  }

  #fuel-type-grid {
    width: 100%;
  }

  .parent-wrapper {
    background-color: #363640;
    color: white;
  }

  .col-sub-header-title {
    white-space: normal;
  }

  .toolbar-wrapper {
    background-color: #ddd;
  }

  .heading-text {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: inherit;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort,
  .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort-indicator,
  .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content {
    color: #fff;
  }
}
