.codesTable::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  transition: background-color 0.2s ease-in, color 0.3s ease-out;
}
.codesTable::-webkit-scrollbar-thumb {
  color: transparent;
  transition: background-color 0.2s ease-in, color 0.3s ease-out;
}
.codesTable::-webkit-scrollbar-thumb:hover {
  background: #c5c5c5;
}
.codesTable::-webkit-scrollbar-thumb:window-inactive {
  background: #f1f1f1;
}

.codesTable {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  tbody {
    tr {
      overflow: scroll;
    }
  }
  .codeButtons {
    margin: 0.375rem 0.375rem;
    transition: background-color 0.2s ease-in, color 0.3s ease-out;
  }
}
