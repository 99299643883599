.iftMain {
  .tabs {
    margin: 0px 16px 16px 16px;
  }

  .ift-schedule-tree {
    .bg-green {
      td {
        background-color: #b2dcc6;
      }
    }
  }

  .scopeMain {
    padding: 8px 16px 16px 16px;

    .scopeHeader {
      display: flex;
      gap: 1rem;
      margin: 0 0 0.5rem 0.25rem;

      .scopeHeaderSwitch {
        font-weight: bold;
        display: flex;
        gap: 0.5rem;
      }
    }

    .ift-scope-tree {
      .bg-added {
        background-color: #ffffc5;
      }
      .bg-grey {
        background-color: #d3d3d3;
      }
      .bg-cream {
        background-color: #f8de7e;
      }
      .bg-green {
        background-color: #b2dcc6;
      }
      .dx-link {
        color: #363640;
      }
      .cell-description {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
          margin-left: 0.25rem;
        }
      }
    }
  }
}

.ift-provisional-sum {
  .bg-grey {
    background-color: #d3d3d3;
  }
}

.ift-scope-item {
  .bg-grey {
    background-color: #d3d3d3;
  }
}

.editItemTable {
  padding: 0.5rem !important;

  .bg-grey {
    background-color: #d3d3d3;
  }
}

.item-modal-pop-up {
  .ift-scope-item-add-wrapper {
    flex: 1;
    display: flex;
    height: 100%;
    gap: 10px;

    .ift-scope-item-group-grid {
      display: flex;
      flex: 0 0 15.625rem;
      max-height: calc(100vh - 10.75rem);
    }

    .ift-scope-items-selected-items-grid {
      display: flex;
      flex: 1;
      gap: 15px;
      flex-direction: column;

      .ift-scope-items-grid {
        flex: 1;
        display: flex;
        max-height: calc(100vh - 28.25rem);

        @media (max-width: 1397px) {
          max-height: calc(100vh - 21rem);
        }
      }
    }
  }
}

.scope-update-resource-popup-wrapper {
  .dx-accordion {
    .dx-accordion-wrapper {
      .dx-accordion-item-opened .dx-accordion-item-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
      }
    }
  }

  .save-btn {
    padding: 1rem 8rem !important;
  }
}
