@import "../../../themes/generated/variables.base.scss";

.CostEstimateSummaryReportMain {
  .cost-estimate-report-head {
    padding: 20px;

    .info-group {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      width: 100%;
      height: min-content;

      .info-cell {
        display: flex;
        gap: 8px;

        label {
          font-weight: bold;
          min-width: fit-content;
        }

        div {
          text-align: end;
        }
      }

      @media (max-width: 1062px) {
        column-gap: 32px;
        grid-template-columns: repeat(2, 1fr);

        .info-cell {
          justify-content: space-between;
        }
      }

      @media (max-width: 376px) {
        grid-template-columns: repeat(1, 1fr);

        .info-cell {
          justify-content: space-between;
        }
      }
    }

    .toggle-head {
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
      gap: 15px;

      span {
        display: flex;
        gap: 10px;

        label {
          font-weight: bold;
        }
      }
    }
  }

  .cost-summary-grid {
    .cost-summary-report-header-cell {
      display: flex;
      align-items: flex-end;

      .cost-summary-report-carbon-toggle {
        margin-right: 0.625rem;
        border: 0.111rem solid #e5e5e5;
        padding: 0.1rem 0.156rem;
        border-radius: 50%;
        font-size: 14px;
        transition: background-color 0.2s ease-in, color 0.3s ease-out;
        cursor: pointer;

        &:hover {
          background-color: #626273;
          color: #fff;
        }
      }

      .cost-summary-report-header-value {
        color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .bg-summary {
      background-color: #50af7d;
      font-weight: bold;
    }
    .bg-org {
      background-color: #b2dcc6;
    }
    .bg-section {
      background-color: #e2f2e9;
    }
    .bg-composite-total {
      background-color: #e8f8f4;
    }
  }
}
